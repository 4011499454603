body {
    margin: 0;
    /* background-image: url('./Assets/Images/bg-1.avif');
    background-size: cover; */
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: rgba(230,206,169,1);
    /* background: linear-gradient(90deg, rgba(255,206,169,1) 0%, rgba(230,206,169,1) 27%, rgba(161,197,201,1) 100%); */
    background-image: linear-gradient(90deg, rgb(255 206 169 / 85%) 0%, rgb(230 206 169 / 85%) 27%, rgb(161 197 201 / 85%) 100%), url('./Assets/Images/candy-bg.jpeg');
    background-size: contain;
    background-position: bottom;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

a {
    text-decoration: none;
    color: black;
}

/* App drawer */
.MuiPaper-elevation16 {
    background-color: #f4f2f2 !important;
}