.blink {
    animation: blink-animation 2.5s steps(5, start) infinite;
    -webkit-animation: blink-animation 2.5s steps(5, start) infinite;
  }
  @keyframes blink-animation {
    to {
      visibility: hidden;
    }
  }
  @-webkit-keyframes blink-animation {
    to {
      visibility: hidden;
    }
  }